import Link from 'next/link';
import React from 'react';
import { Anchor } from '#/src/components/NavAnchor';
import { BetaLabel } from '#/src/components/Page/Logo/BetaLabel';
import { LogoImg } from '#/src/components/Page/Logo/LogoImg';

export const Logo = (props: {
  isDark?: boolean;
  width?: number;
  disableBy?: boolean;
  withLink?: boolean;
}) => {
  const { isDark, width, disableBy, withLink } = props;

  const logoElement = (
    <div className={'text-center relative'}>
      <div className='flex justify-center items-center gap-2 h-12'>
        <LogoImg isDark={isDark} width={width || 48} />
        <BetaLabel />
      </div>
      {!disableBy && (
        <Anchor
          size={'xs'}
          href={'https://superside.com'}
          target={'_blank'}
          className={
            'text-gray-500 dark:text-gray-500 tracking-wide absolute right-1/2 translate-x-1/2 translate-y-3 bottom-0'
          }
        >
          by Superside
        </Anchor>
      )}
    </div>
  );

  if (withLink) {
    return <Link href={'/'}>{logoElement}</Link>;
  }

  return logoElement;
};
