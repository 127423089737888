import Image, { ImageProps } from 'next/image';
import React from 'react';
import logoSvg from '#/src/assets/logo.svg';
import logoLightSvg from '#/src/assets/logo_light.svg';

export interface LogoImgProps extends Omit<ImageProps, 'height' | 'src' | 'alt'> {
  width: number;
  isDark?: boolean;
  alt?: string;
}

export const LogoImg: React.FC<LogoImgProps> = (props) => {
  const { width, isDark, alt = 'Your Company', ...rest } = props;
  const Img = isDark ? logoLightSvg : logoSvg;

  return (
    <Image
      {...rest}
      priority
      width={(width / Img.height) * Img.width}
      height={Img.height}
      src={Img.src}
      alt={alt}
    />
  );
};
