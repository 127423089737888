'use client';
import Link from 'next/link';
import React from 'react';
import { classNames } from '#/packages/ui';
import { GirlIllurtartion } from '#/src/components/auth/assets/GirlIllurtartion';
import { VlogIllustration } from '#/src/components/auth/assets/VlogIllustration';
import { Logo } from '#/src/components/Page/Logo/Logo';
import starsSvg from './assets/stars.svg';

export interface AuthenticationLayoutProps {
  from?: string;
  header?: React.ReactNode;
  isModal?: boolean;
  withTerms?: boolean;
  withIllustrations?: boolean;
  children: React.ReactNode;
}

export default function AuthenticationLayout(props: AuthenticationLayoutProps) {
  const { isModal, withTerms, withIllustrations } = props;

  return (
    <div
      id={props.id}
      className={classNames(
        'relative flex flex-col min-h-[100vh] sm:pt-10 pb-4',
        'bg-neutral-1',
        isModal ? 'h-screen overflow-hidden' : '',
        'text-black dark:text-gray-100'
      )}
    >
      <StarsBackground />
      <div
        className={classNames(
          'relative z-20 flex flex-1 flex-col w-full h-full sm:h-auto sm:justify-center mx-auto max-w-[1500px] sm:px-6 lg:px-8',
          'min-h-full'
        )}
      >
        <div
          className={classNames(
            'max-sm:flex max-sm:flex-col max-sm:flex-1 z-20 relative backdrop-blur-lg sm:mx-auto sm:w-full sm:max-w-[480px] sm:rounded-xl',
            'transition-all duration-1000',
            isModal ? 'max-sm:h-full' : '',
            'bg-neutral-6/[.05]',
            'sm:shadow-[0_0_118px_0_rgba(0,0,0,0.1)]'
          )}
        >
          <div
            className={classNames(
              'max-sm:flex max-sm:flex-col max-sm:flex-1 max-sm:h-full px-6 py-12 sm:px-12'
            )}
          >
            <Logo />
            <div className={'mt-8 mb-4'}>
              {props.header ? (
                <h2 className='text-center text-gray-700 text-xl font-bold leading-9 tracking-wide text-black dark:text-gray-100'>
                  {props.header}
                </h2>
              ) : null}
            </div>
            {props.children}
          </div>
        </div>

        {withTerms ? <Terms /> : null}

        {withIllustrations ? (
          <div
            className={
              'absolute z-10 top-1/2 -translate-y-1/2 right-0 w-1/3 max-w-[400px] max-sm:hidden'
            }
          >
            <GirlIllurtartion />
          </div>
        ) : null}
        {withIllustrations ? (
          <div
            className={
              'absolute z-10 top-1/2 -translate-y-1/2 left-0 w-1/3 max-w-[400px] max-sm:hidden'
            }
          >
            <VlogIllustration />
          </div>
        ) : null}
      </div>
    </div>
  );
}

const Terms = () => (
  <p className={'text-center text-xs text-gray-400 mt-4 mb-4'}>
    By continuing, you agree to our{' '}
    <Link
      target={'_blank'}
      href={'https://www.superads.ai/terms-of-use'}
      className={'text-gray-500 whitespace-nowrap'}
    >
      Terms of Use
    </Link>{' '}
    and{' '}
    <Link
      target={'_blank'}
      href={'https://www.superads.ai/privacy'}
      className={'text-gray-500 whitespace-nowrap'}
    >
      Privacy Policy
    </Link>
    .
  </p>
);

const StarsBackground = () => (
  <div
    className={`fixed z-10 top-0 left-0 inset-0 max-sm:hidden`}
    style={{
      backgroundImage: `url(${starsSvg.src})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat-y'
    }}
  />
);
