'use client';
import Link, { LinkProps } from 'next/link';
import React from 'react';
import { classNames } from '#/packages/ui/src/utils/classNames';

export interface AnchorProps extends React.ComponentProps<'a'> {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  Component?: React.FC<any>;
}

export const Anchor = (props: AnchorProps) => {
  const { size = 'md', Component = 'a', ...rest } = props;

  return (
    <Component
      {...rest}
      className={classNames(
        'inline-flex items-center cursor-pointer',
        'font-medium leading-6 transition-colors',
        {
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-md': size === 'md',
          'text-lg': size === 'lg'
        },
        'text-primary-6 hover:text-primary-8',
        'dark:text-[rgb(126,152,253)] dark:hover:text-[rgb(159,178,255)]',
        props.className
      )}
    >
      {props.children}
    </Component>
  );
};

export const NavAnchor = (props: AnchorProps & LinkProps) => {
  return <Anchor {...props} Component={Link} />;
};
